@tailwind utilities;

/* For opening and closing an accordion element */
/************************************************/

/* For the height animation */
.AccordionContent[data-state='open'] {
  animation: slideDown 500ms cubic-bezier(0.87, 0, 0.13, 1);

  -webkit-animation: slideDown 500ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 500ms cubic-bezier(0.87, 0, 0.13, 1);

  -webkit-animation: slideUp 500ms cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

/* For the border and background color animation */
.AccordionItem[data-state='open'] {
  animation: itemOpen 500ms cubic-bezier(0.87, 0, 0.13, 1);

  -webkit-animation: itemOpen 500ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionItem[data-state='closed'] {
  animation: itemClose 500ms cubic-bezier(0.87, 0, 0.13, 1);

  -webkit-animation: itemClose 500ms cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes itemOpen {
  from {
    @apply border-yellow-100/0;
  }
  to {
    @apply border-yellow-100/100;
  }
}

@keyframes itemClose {
  from {
    @apply border-yellow-100/100;
  }
  to {
    @apply border-yellow-100/0;
  }
}

/* For the Trigger background color and text size */
.AccordionTrigger[data-state='open'] > div:nth-child(2), .AccordionTrigger[data-state='open'] > div:only-child {
  -webkit-animation: triggerOpenDesktop 500ms cubic-bezier(0.87, 0, 0.13, 1);
  animation: triggerOpenDesktop 500ms cubic-bezier(0.87, 0, 0.13, 1);
}

@media screen(md) {
  .AccordionTrigger[data-state='open'] > div:nth-child(2), .AccordionTrigger[data-state='open'] > div:only-child {
    -webkit-animation: triggerOpenMobile 500ms cubic-bezier(0.87, 0, 0.13, 1);
    animation: triggerOpenMobile 500ms cubic-bezier(0.87, 0, 0.13, 1);
  }
}


.AccordionTrigger[data-state='closed'] > div:nth-child(2), .AccordionTrigger[data-state='closed'] > div:only-child {
  -webkit-animation: triggerCloseDesktop 500ms cubic-bezier(0.87, 0, 0.13, 1);
  animation: triggerCloseDesktop 500ms cubic-bezier(0.87, 0, 0.13, 1);
}
@media screen(md) {
  .AccordionTrigger[data-state='closed'] > div:nth-child(2), .AccordionTrigger[data-state='closed'] > div:only-child {
    -webkit-animation: triggerCloseMobile 500ms cubic-bezier(0.87, 0, 0.13, 1);
    animation: triggerCloseMobile 500ms cubic-bezier(0.87, 0, 0.13, 1);
  }
}

@keyframes triggerOpenDesktop {
  from {
    /* Font-size Medium */
    @apply text-500-d bg-yellow-25;
  }
  to {
    /* Font-size Large */
    @apply text-700-d bg-common-white;
  }
}

@keyframes triggerCloseDesktop {
  from {
    /* Font-size Large */
    @apply text-700-d bg-common-white;
  }
  to {
    /* Font-size Medium */
    @apply text-500-d bg-yellow-25;
  }
}

@keyframes triggerOpenMobile {
  from {
    /* Font-size Medium */
    @apply text-500-m bg-yellow-25;
  }
  to {
    /* Font-size Large */
    @apply text-700-m bg-common-white;
  }
}

@keyframes triggerCloseMobile {
  from {
      /* Font-size Large */
    @apply text-700-m bg-common-white;
  }
  to {
    /* Font-size Medium */
    @apply text-500-m bg-yellow-25;
  }
}

/************************************************/
