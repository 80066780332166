@import 'react-phone-number-input/style.css';
@tailwind utilities;

.PhoneInput {
  @apply w-full;
}

.PhoneInputInput {
  @apply outline-none bg-transparent;
}
