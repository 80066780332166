@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Planar;
    src: url(/v2/fonts/GT-Planar-VF.woff2) format('woff2 supports variations'),
    url(/v2/fonts/GT-Planar-VF.woff2) format('woff2-variations');
    font-weight: 100 900;
  }

  .font-slant-neg45 {
    font-variation-settings: 'slnt' -45;
  }

  .font-slant-neg30 {
    font-variation-settings: 'slnt' -30;
  }

  .font-slant-neg15 {
    font-variation-settings: 'slnt' -15;
  }

  .font-slant-0 {
    font-variation-settings: 'slnt' 0;
  }

  .font-slant-pos15 {
    font-variation-settings: 'slnt' 15;
  }

  .font-slant-pos30 {
    font-variation-settings: 'slnt' 30;
  }

  .font-slant-pos45 {
    font-variation-settings: 'slnt' 45;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Planar', Helvetica, Arial, sans-serif;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ss05' on;
    font-variation-settings: 'slnt' 0;
  }

  html,
  body {
    max-width: 100vw;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
}
