@tailwind utilities;

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  flex-shrink: 0;
  display: flex;
  width: auto;
}

.Toastify__toast-body {
  @apply flex items-center flex-auto m-0 mr-2 py-3 pl-4 pr-2;
}

.Toastify__toast-container {
  @apply w-auto;
}
