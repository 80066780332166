.fade-enter {
  -webkit-opacity: 0;

  opacity: 0;
}

.fade-enter-active {
  -webkit-opacity: 1;
  -webkit-transition: opacity 800ms ease-in;

  opacity: 1;
  transition: opacity 800ms ease-in;
}

.fade-exit {
  -webkit-opacity: 1;
  opacity: 1;

  position: absolute;
  top: 0;
}

.fade-exit-active {
  -webkit-opacity: 0;
  -webkit-transition: opacity 800ms ease-in;

  opacity: 0;
  transition: opacity 800ms ease-in;

  position: absolute;
  top: 0;
}
