@tailwind utilities;

.animation_loader_a{
  -webkit-animation-name: animation;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;

  animation-name: animation;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
  @apply fill-common-black;
}

.animation_loader_n{
  -webkit-animation-name: animation;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-delay: 0.0875s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;

  animation-name: animation;
  animation-duration: 0.7s;
  animation-delay: 0.0875s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
  @apply fill-common-black;
}

.animation_loader_e{
  -webkit-animation-name: animation;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-delay: 0.175s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;

  animation-name: animation;
  animation-duration: 0.7s;
  animation-delay: 0.175s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
  @apply fill-common-black;
}

.animation_loader_o{
  -webkit-animation-name: animation;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-delay: 0.2625s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;

  animation-name: animation;
  animation-duration: 0.7s;
  animation-delay: 0.2625s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
  @apply fill-common-black;
}

@-webkit-keyframes animation {
  0% {
    @apply fill-common-black;
  }
  12.5% {
    @apply fill-common-black;
  }
  25.0% {
    @apply fill-common-black;
  }
  37.5% {
    @apply fill-common-black;
  }
  50.0% {
    @apply fill-gray-100;
  }
  62.5% {
    @apply fill-gray-80;
  }
  75.0% {
    @apply fill-gray-60;
  }
  87.5% {
    @apply fill-gray-40;
  }
}

@keyframes animation {
  0% {
    @apply fill-common-black;
  }
  12.5% {
    @apply fill-common-black;
  }
  25.0% {
    @apply fill-common-black;
  }
  37.5% {
    @apply fill-common-black;
  }
  50.0% {
    @apply fill-gray-100;
  }
  62.5% {
    @apply fill-gray-80;
  }
  75.0% {
    @apply fill-gray-60;
  }
  87.5% {
    @apply fill-gray-40;
  }
}
